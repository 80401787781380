/*
  ----------------
  History related styles
  ----------------
*/

.history {
  @include padding(70px null 80px);

  @media #{$sp}{
    @include padding(35px null 0);
  }
  &__wrap {
    @include container;
  }

  &__txt {
    line-height: 2.142;
    margin-bottom: 35px;
    @media #{$sp}{
      font-size: 14px;
      line-height: 1.785;
    }
  }
}
// Container

@mixin container {
  max-width: #{$container};
  @include margin(null auto);
  position: relative;

  @media #{$sp} {
    @include padding(null 15px);
  }
}


// Clear fix （Bourbon には入っているけど、念のために）

@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}


// Hover opacitiy for links ( just so they're all the same )

@mixin hoverlink {
  transition: 0.2s ease-in-out;
  backface-visibility: hidden;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  &::before,
  &::after {
    backface-visibility: hidden;
  }
}

// Futura
@mixin futura {
  font-family: 'Ubuntu', sans-serif;
}



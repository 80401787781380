/*
  ----------------
  Company related styles
  ----------------
*/

.link-primary {
  color: $accent-color;
  display: inline-block;
  font-weight: bold;
  position: relative;
  padding-left: 15px;
  border-bottom: 1px solid transparent;
  transition: all 0.2s;
  @media #{$sp}{
    
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    @include size(8px 3px);
    background: url(../img/common/arrow_right_red.svg) 50% / contain no-repeat;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: all 0.2s;

    @media #{$sp}{
      @include size(11px 5px);
    }
  }
  
  &:hover {
    border-bottom: 1px solid $accent-color;
    &::before {
      transform: translate(.25em,-50%);
    }
  }
}
/*
  ----------------
  選択する際の色設定
  ----------------
*/

::selection {
  background: rgba($accent-color,0.2);
}

::-moz-selection {
  background: rgba($accent-color,0.2);
}

img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}

body {
  -webkit-tap-highlight-color: rgba($accent-color,0.2);
}

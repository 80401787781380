/*
  ----------------
  Recruit related styles
  ----------------
*/

.recruit {

  &__hero {
    @media #{$pc} {
      font-size: 0;
      width: 100%;
      margin-bottom: 56px;
      position: relative;
      overflow: hidden;
      background: url(../img/recruit/hero_pc.jpg) 50% / cover no-repeat;
      @include padding(80px null);
    }
    @media #{$sp} {
      margin-bottom: 25px;
      background: url(../img/recruit/hero_sp.jpg) 50% / cover no-repeat;
      @include padding(35px null 40px);
    }
  }

  &__txt {
    @include container;
    font-size: 15px;
    line-height: 2.714;

    @media #{$sp}{
      font-size: 12px;
      line-height: 2;
    }

    &--message {
      font-size: 18px;
      text-align: center;
      @media #{$sp}{
        font-size: 13px;
        text-align: left;
      }
    }
  }

  &__txt-par {
    margin-bottom: 18px;

    &--hero {
      margin-bottom: 40px;
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      line-height: 2.2;
      color: #000;
      text-shadow: 0px 0px 7.1px rgba(255, 255, 255, 1);
      @media #{$sp}{
        margin-bottom: 30px;
        font-size: 15px;
      }
    }

    &--hero-2 {
      margin-bottom: 0;
      font-size: 17px;
      @media #{$sp}{
        margin-bottom: 20px;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }


  &__related {
    @include padding(40px null 80px);
    
    @media #{$sp}{
      @include padding(10px null 40px);
    }
  }

  &__related-wrap {
    @include container;
  }

  &__related-img {
    @media #{$sp}{
      object-fit: cover;
      height: 40vw;
    }

    &--newlygraduate {
      @media #{$sp}{
        object-position: left top;
      }
    }
  }

  &__heading {
    margin-bottom: 40px;
    @media #{$sp}{
      margin-bottom: 25px;
    }
  }

  &__list {
    @include row;
  }

  &__item {
    @media #{$pc}{
      @include col-6;
    }

    @media #{$sp}{
      width: 50%;
      @include padding(null calc(#{$gutter-half} / 2));
    }
  }

  &__movie {
    text-align: center;
    @media #{$pc}{
      position: absolute;
      bottom: 25px;
      right: 20px;
    }
  }

  &__movie-ttl {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    color: #fff;
    @media #{$sp}{
      font-size: 12px;
      color: $text-color;
      text-shadow: 0px 0px 7px #fff, 0px 0px 7px #fff, 0px 0px 7px #fff;
    }
  }

  &__movie-btn {
    font-size: 0;
    text-align: center;
    line-height: 1;
  }

  &__colorbox {
    cursor: pointer;
    width: 260px;
    border: 6px solid #fff;
    img {
      width: 100%;
    }
    @media #{$sp}{
      width: 160px;
    }
  }
}
/*
  ----------------
  Gritway related styles
  ----------------
*/

.vision {

  &__hero-wrap {
    @media #{$pc} {
      font-size: 0;
      width: 100%;
      height: 430px;
      margin-bottom: 75px;
      position: relative;
      overflow: hidden;
      @media #{$sp} {
        margin-bottom: 40px;
      }
    }

    &--vision01 {
      @media #{$pc} {
        background: url(../img/gritway/hero_vision01_pc.jpg) 50% / cover no-repeat;
      }
      @media #{$sp} {
        background: url(../img/gritway/hero_vision01_sp.jpg) 100% / cover no-repeat;
        margin-bottom: 60px;
      }
    }

    &--vision02 {
      @media #{$pc} {
        background: url(../img/gritway/hero_vision02_pc.jpg) 50% / cover no-repeat;
      }
      @media #{$sp} {
        background: url(../img/gritway/hero_vision02_sp.jpg) 100% / cover no-repeat;
        margin-bottom: 60px;
      }
    }
  }

  &__hero {
    @include container;
    height: 100%;
    display: flex;
    align-items: center;

    &--vision02 {
      justify-content: center;
    }
  }

  &__catch {
    &--vision01 {
      font-size: 36px;
      font-weight: bold;
      color: #e10000;
    }
    &--vision02 {
      font-size: 36px;
      font-weight: bold;
      color: #fff;
      text-align: center;
    }
    @media #{$sp} {
      &--vision01,
      &--vision02 {
        width: 100%;
        margin: 80px auto;
        font-size: 20px;
        color: #fff;
        text-align: center;
      }
    }
  }

  &__catch-intro {
    &--vision01 {
      font-size: 20px;
      color: #{$text-color};
    }
    &--vision02 {
      font-size: 20px;
      color: #fff;
    }
    @media #{$sp} {
      &--vision01,
      &--vision02 {
        font-size: 14px;
        color: #fff;
      }
    }
  }

  &__detail {
    @include container;
    margin-bottom: 75px;
    @media #{$sp} {
      margin-bottom: 40px;
    }

    &--developmentcenter {
      max-width: 1280px;
    }

    &--gritglobal {
      margin-bottom: -32px;
      color: #fff;
    }
  }

  &__gritglobal-wrap {
    display: flex;
    align-items: center;
    height: 400px;
    margin-bottom: 75px;
    background: url(../img/gritway/gritglobal_bg_pc.png) 50% / cover no-repeat;
    @media #{$sp} {
      margin-bottom: 40px;
      height: 270px;
      .vision__txt {
        text-align: center;
      }
    }
  }

  &__txt-wrap {
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 2;
    text-align: center;
  }

  &__txt {
    &--gritglobal01 {
      margin-bottom: 26px;
      font-size: 16px;
      font-weight: bold;
    }

    &--gritglobal02 {
      font-size: 12px;
    }
    @media #{$sp} {
      font-size: 14px;
      text-align: left;
    }
  }

  &__img-wrap {
    text-align: center;
  }

  &__img {
    &--potentialcareer {
      width: 100%;
    }

    &--gritvalue {
      width: 692px;
    }

    &--developmentcenter01 {
      width: 600px;
      margin-bottom: 80px;
    }

    &--developmentcenter02 {
      width: 1280px;
    }
  }

  &__related {
    @include padding(null null 80px);
  }

  &__related-wrap {
    @include container;
  }

  &__related-img {
  }

  &__list {
    @include row;
    justify-content: center;
  }

  &__item {
    @include col-4;
  }
}

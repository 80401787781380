/*
  ----------------
  ホームページ
  ----------------
*/

.top-hero {
  @include size(100% 430px);
  background: url(../img/top/hero_bg_pc.jpg) 50% / cover no-repeat;
  position: relative;

  @media #{$sp}{
    height: calc(100vh - 40px);
    background: url(../img/top/hero_bg_sp.jpg) 50% / cover no-repeat;
  }

  .particles-js-canvas-el {
    position: absolute;
    top: 0;
  }

  &__wrap {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    height: 100%;
    position: relative;
    z-index: 1;
  }

  &__catch {
    width: 100%;
    // @include futura;
    // font-size: 53px;
    // font-weight: bold;
    // font-style: italic;
    // text-align: center;
    // color: #e10000;
    // letter-spacing: -2px;
    // text-shadow: #fff 0 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media #{$sp}{
      // font-size: 44px;
      // letter-spacing: -1px;
      margin-bottom: 20px;
      img {
        max-width: 350px;
      }
    }
  }

  // &__catch-sub {
  //   width: 100%;
  //   font-size: 18px;
  //   font-style: italic;
  //   text-align: center;
  //   font-weight: bold;
  //   color: #fff;
  //   text-shadow: $text-color 0 0 10px;
  //   @media #{$sp}{
  //     margin-bottom: 20px;
  //     font-size: 12px;
  //     color: $text-color;
  //     text-shadow: #000 0 0 10px;
  //   }
  // }

  &__movie {
    text-align: center;
    @media #{$pc}{
      position: absolute;
      bottom: 40px;
      right: 30px;
      
    }
  }

  &__movie-ttl {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    color: $text-color;
    text-shadow: 0px 0px 7px #fff, 0px 0px 7px #fff, 0px 0px 7px #fff;
    @media #{$sp}{
      font-size: 12px;
      color: $text-color;
      text-shadow: 0px 0px 7px #fff, 0px 0px 7px #fff, 0px 0px 7px #fff;
    }
  }

  &__movie-btn {
    font-size: 0;
    text-align: center;
    line-height: 1;
    border: 6px solid #fff;
  }

  &__colorbox {
    cursor: pointer;
    width: 260px;
    img {
      width: 100%;
    }
    @media #{$sp}{
      width: 160px;
    }
  }

  &__scroll {
    @include padding(30px);
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);

    @media #{$sp}{
      @include padding(20px);
    }

    img {
      transition: all .2s;
    }

    @media #{$pc}{
      &:hover {
        img {
          transform: translateY(15px);
        }
      }
    }
  }
}

.top-keywords {
  @include padding(50px null 80px);

  @media #{$sp}{
    @include padding(40px null);
  }

  &__heading {
    margin-bottom: 40px;

    @media #{$sp}{
      margin-bottom: 30px;
    }
  }

  &__swiper-container {

  }

  &__swiper-wrapper {

  }

  &__slide {
    width: auto;
    opacity: 0.2;
    transition: all 0.2s;
    @include padding(null 15px);

    @media #{$sp}{
      max-width: 230px;
      @media #{$sp}{
        @include padding(null 12px);
      }
    }

    @media #{$pc}{
      pointer-events: none;
      &.swiper-slide-prev,
      &.swiper-slide-active,
      &.swiper-slide-next {
        opacity: 1;
        pointer-events: auto;
      }
    }
    @media #{$sp}{
      &.swiper-slide-active {
        opacity: 1;
      }
    }
  }
}

.top-news {
  background: #f2f2f2;
  @include padding(75px null);

  @media #{$sp}{
    @include padding(40px null 50px);
  }

  &__wrap {
    @include container;
  }

  &__heading {
    margin-bottom: 50px;

    @media #{$sp}{
      margin-bottom: 15px;
    }
  }

  &__btn {
    display: flex;
    margin-top: 30px;
    margin-left: auto;

    @media #{$sp}{
      margin-top: 20px;
      margin-left: 0;
    }
  }
}

.top-recruit {
  @include padding(90px null);
  background: url(../img/top/recruit_bg.jpg) 50% / cover no-repeat;

  @media #{$sp}{
    @include padding(55px null);
    background: url(../img/top/recruit_bg_sp.jpg) 50% / cover no-repeat;
  }
  &__wrap {
    @include container;
  }

  &__heading {
    margin-left: -75px;
    margin-bottom: 5px;
    @media #{$sp}{
      margin-left: 0;
      margin-bottom: 20px;
      text-align: left;
    }
  }

  &__content {
    margin-left: -75px;
    display: flex;
    align-items: center;

    @media #{$sp}{
      flex-direction: column;
      margin-left: 0;
    }
  }

  &__txt {
    margin-right: 45px;
    font-size: 13px;
    font-weight: bold;

    @media #{$sp}{
      display: none;
      margin-bottom: 20px;
    }

    p:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  &__btn {
    flex-shrink: 0;

    @media #{$sp}{
      align-self: flex-start;
    }
  }
}

.top-group {
  @include padding(100px null 80px);
  background: #f2f2f2;

  @media #{$sp}{
    @include padding(40px null);
  }

  &__wrap {
    @include container;
  }

  &__heading {
    margin-bottom: 40px;
  }

  &__cat-list {

  }

  &__cat-item {
    &:not(:last-child) {
      margin-bottom: 45px;

      @media #{$sp}{
        margin-bottom: 30px;
      }
    }
  }

  &__cat-name {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 10px;
    padding-left: 15px;
    border-bottom: 1px dashed rgba($accent-color, .4);
    margin-bottom: 15px;
  }

  &__list {
    @include row;
    justify-content: flex-start;
    padding-bottom: 5px;
    @media #{$sp}{
      width: calc(100% + 6px);
      @include margin(null -3px);
      padding-bottom: 0;
    }
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    @media #{$pc}{
      @include col-3;
      &:not(:nth-child(-n+4)) {
        margin-top: $gutter;
      }
    }

    @media #{$sp}{
      width: 50%;
      @include padding(null 3px);
      text-align: center;
      &:not(:nth-child(-n+2)) {
        margin-top: 6px;
      }
    }
    a.top-group__link {
      &:hover {
        box-shadow: 1px 1px 10px rgba($text-color, 0.2);
      }
    }
  }

  &__link {
    display: block;
    background: #FFF;
    text-align: center;
    transition: all 0.2s;
    padding-bottom: 8px;
    letter-spacing: -0.5px;
    overflow: hidden;
    border: 1px solid #ddd;
    h3 {
      border-bottom: 1px solid #DDD;
      margin: 0 10px 3px;
      font-weight: bold;
      font-size: 11px;
      @media #{$sp}{
        font-size: 10px;
      }
    }
    p {
      margin: 0 10px;
      line-height: 1.5;
      font-size: 10px;
    }
    
  }
}

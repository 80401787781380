/*
  ----------------
  Base styles
  ----------------
*/

body {
  font-family: $font-family;
  font-size: 14px;
  color: $text-color;
  position: relative;
  padding-top: 70px;

  @media #{$sp}{
    font-size: 12px;
    padding-top: 40px;

    &.menu-is-open {
      overflow: hidden;
    }
  }
}

/*
  ----------------
  List styles
  ----------------
*/

.list-primary {
  @include padding(null 83px);

  &--sp-white {
    @media #{$sp}{
      background: #fff;
    }
  }

  &--transparent {
    background: transparent;
  }

  @media #{$sp}{
    @include margin(null -15px);
    @include padding(null 15px);
  }

  &__item {
    @include padding(10px null);
    display: flex;
    align-items: flex-start;

    @media #{$pc}{
      &:not(:last-child) {
        border-bottom: 1px dashed rgba($text-color, .3);
      }
    }

    @media #{$sp}{
      @include padding(0px null);
      flex-direction: column;
    }

  }

  &__ttl {
    flex-shrink: 0;
    @include padding(14px null);
    width: 166px;
    font-weight: bold;
    line-height: 1;
    color: #333;

    @media #{$sp}{
      @include padding(10px null);
      width: 100%;
      margin-right: 0;
      border-bottom: 1px dotted rgba($text-color, .3);
      font-size: 14px;
    }
  }

  &__txt {
    flex-grow: 1;
    margin-top: .5em;
    line-height: 2;

    @media #{$sp}{
      width: 100%;
      margin-bottom: 20px;
      padding-left: 0.2em;
      font-size: 14px;
    }

    p:not(:last-child) {
      margin-bottom: 1em;
    }
  }


}

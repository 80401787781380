/*
  ----------------
  Grid system
  ----------------
*/


@mixin row {
  width: calc(100% + #{$gutter});
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: - #{$gutter-half};
  margin-right: - #{$gutter-half};
  overflow: hidden;

  @media #{$sp}{
    width: calc(100% + #{$gutter} / 2);
    margin-left: - calc(#{$gutter-half} / 2);
    margin-right: - calc(#{$gutter-half} / 2);
  }
}

.row {
  @include row;
}

@mixin col-3 {

  @media #{$pc} {
    width: 25%;
    @include padding(null #{$gutter-half});
  }

  @media #{$sp} {
    width: 100%;
  }
}

.col-3 {
  @include col-3;
}


@mixin col-4 {


  @media #{$pc} {
    width: 33.33%;
    @include padding(null #{$gutter-half});
  }

  @media #{$sp} {
    width: 100%;
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
}

.col-4 {
  @include col-4;
}

.col-5 {

  @media #{$pc} {
    width: 41.66%;
    @include padding(null #{$gutter-half});
  }

  @media #{$sp} {
    width: 100%;
  }
}

@mixin col-6 {
  @media #{$pc} {
    width: 50%;
    @include padding(null #{$gutter-half});
  }

  @media #{$sp} {
    width: 100%;
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
}

.col-6 {
  @include col-6;
}

@mixin col-7 {
  @media #{$pc} {
    width: 58.33%;
    @include padding(null #{$gutter-half});
  }

  @media #{$sp} {
    width: 100%;
  }
}

.col-7 {
  @include col-7;
}
/*
  ----------------
  Headings
  ----------------
*/

.heading-page {
  background: #e10000;

  &__wrap {
    @include container;
    @include padding(45px null);
    display: flex;
    align-items: center;

    @media #{$sp}{
      @include padding(20px null);
    }
  }

  &__en {
    @include futura;
    font-size: 34px;
    font-weight: bold;
    letter-spacing: 0.16em;
    color: #fff;
    margin-right: 0.6em;

    @media #{$sp}{
      font-size: 24px;
      flex-shrink: 0;
    }
  }

  &__jp {
    color: #fff;
  }
}

.heading-primary {
  margin-bottom: 25px;
  @media #{$sp}{
    text-align: center;
  }
  &__ttl {
    display: block;
    @include futura;
    font-size: 28px;
    font-weight: bold;
    color: $text-color;

    @media #{$sp}{
      font-size: 24px;
    }
  }

  &__subttl {
    font-size: 13px;
    font-weight: bold;
    color: $accent-color;

    @media #{$sp}{
      font-size: 12px;
    }
  }

  &--center {
    text-align: center;
  }
}

.heading-vision {
  margin-bottom: 26px;
  text-align: center;

  &__ttl {
    display: block;
    @include futura;
    margin-bottom: 14px;
    font-size: 32px;
    font-weight: bold;
    color: #e10000;
    letter-spacing: 2px;
    @media #{$sp} {
      font-size: 24px;
    }
  }

  &__subttl {
    font-size: 14px;
    color: $text-color;
    @media #{$sp} {
      font-size: 12px;
      font-weight: bold;
    }

    &--gritglobal {
      color: #fff;
    }
  }

  &__related {
    font-family: $font-family;
    font-size: 26px;
    @media #{$sp} {
      font-size: 24px;
    }
  }
}
.ttl-red{
    color: $accent-color;
}
.subttl-black{
    color: $text-color;
}
.ttl-white{
    color: #fff;
}
.txt-center{
    text-align: center;
}

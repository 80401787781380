/*
  ----------------
  ヘッダーのスタイル設定
  ----------------
*/

.header {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  
  @media #{$sp}{
    box-shadow: 0 1px 1px rgba(#000, .1);
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include padding(null 20px);
    height: 70px;
    position: relative;

    @media #{$sp}{
      @include padding(null 15px);
      height: 40px;
      background: #fff;
      z-index: 1;
    }
  }

  &__logo {
    max-width: 363px;
    @media #{$sp}{
      max-width: 234px;
    }
  }

  &__overlay {
    @media #{$pc}{
      display: none;
    }
    background: rgba(#000, .6);

    &.is-active {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }
  }

}

.btn-ham {
  @media #{$pc} {
    display: none;
  }

}

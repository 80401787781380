//  @import のみが入ります

// Font
@import url("https://use.typekit.net/avh5ysq.css");
@import url('https://fonts.googleapis.com/css?family=Ubuntu');

// 1 - TOOLS
@import '1-tools/bourbon/bourbon';
@import '1-tools/neat/neat';
@import '1-tools/vars';
@import '1-tools/swiper';
@import '1-tools/normalize';
@import '1-tools/mixins';
@import '1-tools/hamburgers';
@import '1-tools/jquery_colorbox';

// 2 - BASICS
@import '2-basics/general';
@import '2-basics/selection-colors';
@import '2-basics/grid';
@import '2-basics/utility';
@import '2-basics/animation';


// 3 - Modules
@import '3-modules/header';
@import '3-modules/footer';
@import '3-modules/heading';
@import '3-modules/bread';
@import '3-modules/btn';
@import '3-modules/card';
@import '3-modules/page';
@import '3-modules/nav';
@import '3-modules/list';
@import '3-modules/link';
@import '3-modules/tag';


// 4 - pages
@import '4-pages/index';
@import '4-pages/message';
@import '4-pages/news';
@import '4-pages/recruit';
@import '4-pages/history';
@import '4-pages/sectors';
@import '4-pages/privacy';
@import '4-pages/company';
@import '4-pages/contact';
@import '4-pages/about';
@import '4-pages/vision';
@import '4-pages/gritway';

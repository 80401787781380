/*
  ----------------
  Pages shared styles
  ----------------
*/

.page-lower {
  
}

.page-related {
  @include padding(75px null);
  background: #f5f5f5;
  @media #{$sp}{
    @include padding(35px null);
  }

  &__wrap {
    @include container;
  }

  &__list {
    @include row;
  }

  &__item {
    @include col-3;

    @media #{$sp}{
      width: 50%;
      padding-left: calc(#{$gutter-half} / 2);
      padding-right: calc(#{$gutter-half} / 2);
      margin-bottom: 5px;
    }
  }
  
}
/*
  ----------------
  ボタン
  ----------------
*/

.btn-primary {
  @include size(220px 60px);
  display: flex;
  align-items: center;
  @include padding(10px 50px 10px 30px);
  background: 
    url(../img/common/arrow_right.svg) calc(100% - 30px) 50% / 17px 6px no-repeat,
    #e10000;
  border: 1px solid #e10000;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  transition: all 0.2s;

  @media #{$sp}{
    @include size(150px 45px);
    @include padding(10px 25px 10px 20px);
    font-size: 12px;
    @media #{$sp}{
      background: 
        url(../img/common/arrow_right.svg) calc(100% - 20px) 50% / 14px 5px no-repeat,
        #e10000;
    }
  }

  &__txt {
    transition: all 0.2s;  
  }

  &:hover {
    background-position: calc(100% - 15px) 50%, 50%;
    .btn-primary__txt {
      transform: translateX(15px);
    }  
  }

  &--reverse {
    justify-content: flex-end;
    @include padding(10px 30px 10px 50px);
    background: 
      url(../img/common/arrow_left.svg) calc(0% + 30px) 50% / 17px 6px no-repeat,
      $text-color;

    @media #{$sp}{
      @include padding(10px 20px 10px 25px);
      background: 
        url(../img/common/arrow_left.svg) calc(0% + 20px) 50% / 14px 5px no-repeat,
        $text-color;
    }

    &:hover {
      background-position: calc(0% + 15px) 50%, 50%;
      .btn-primary__txt {
        transform: translateX(-15px);
      }  
    }

  }

  &--more {
    cursor: pointer;
    display: none;
    justify-content: center;
    @include padding(10px 30px 10px 50px);
    background: 
      url(../img/common/arrow_down.svg) calc(0% + 30px) 50% / 6px 17px no-repeat,
      #e10000;
    border: 1px solid #e10000;

    &:hover {
      background-position: calc(0% + 30px) calc(50% + 7px);
      .btn-primary__txt {
        transform: translateY(7px);
      }  
    }
  }

}
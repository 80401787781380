/*
  ----------------
  Privacy related styles
  ----------------
*/

.privacy {
  @include padding(60px null 80px);
  background: #f2f2f2;

  @media #{$sp}{
    @include padding(25px null 40px);
  }

  &__wrap {
    @include container;
  }

  &__section {
    &:not(:last-child) {
      margin-bottom: 55px;

      @media #{$sp}{
        margin-bottom: 30px;
      }
    }
  }


  &__ttl  {
    font-size: 16px;
    font-weight: bold;
    color: #b70000;
    margin-bottom: 30px;

    @media #{$sp}{
      margin-bottom: 17px;
    }
  }

  &__subttl {
    font-weight: bold;
    line-height: 2;
  }

  &__txt {
    line-height: 2;

    &:not(:last-child) {
      margin-bottom: 1em;
    }

    p:not(:last-child) {
      margin-bottom: 1em;
    }

    &--right {
      text-align: right;
      margin-top: 2em;
    }
  }

  &__list {

  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 25px;
    }
    & > :not(:first-child) {
      padding-left: 1.7em;
    }
  }

  &__list-txt {

  }

  &__item-txt {
    list-style-type: disc;
    line-height: 2;
    margin-left: 1.5em;
  }

  &__table {
    @media #{$sp}{
      overflow: auto;
    }
    table {
      width: 100%;
    }
    th,
    td {
      @include padding(10px 20px);

      @media #{$sp}{
        white-space: nowrap;
      }
    }

    thead {
      th {
        background: #797979;
        font-weight: bold;
        color: #fff;
        text-align: left;
      }
    }

    tbody {
      tr:not(:last-child) td {
        border-bottom: 1px dashed rgba($text-color, .5);
      }
    }
  }

  &__footer {
    margin-top: 30px;
    @include padding(30px);
    background: #fff;
    border: 1px solid rgba($text-color, .2);
    line-height: 2;
    color: #333;
  }

}

/*
  ----------------
  フッタースタイル設定
  ----------------
*/

.footer {

  &__gotop {
    position: fixed;
    right: 20px;
    bottom: 20px;
    @include size(70px);
    color: #7f7f7f;
    font-size: 0;
    line-height: 0;
    background: #7f7f7f url(../img/common/chevron_up_white.svg) 50% / auto no-repeat;
    cursor: pointer;
    transition: background-position 0.2s;
    display: none;
    z-index: 1;

    @media #{$sp}{
      right: 10px;
      bottom: 10px;
      @include size(40px);
      background-size: 15px 7px;
    }

    &:hover {
      @media #{$pc}{
        background-position: 50% 30%;
      }
    }
  }
  
}

.footer-campaign {
  background: #eaeaea;
  border-top: 1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7;
  @include padding(80px null);

  @media #{$sp}{
    @include padding(35px null 45px);
    background: #eaeaea;
    border-top: 1px solid #c6c6c6;
    border-bottom: 1px solid transparent;
  }

  &__wrap {
    @include container;
  }

  &__swiper-container {
    
  }

  &__swiper-wrapper {
    &--center {
      @media #{$pc}{
        justify-content: center;
      }
    }
  }

  // &__slide {
  //   width: auto;
  //   font-size: 0;
  //   @include hoverlink;
  //   @include padding(null 5px);
  // }
  &__slide {
    width: auto;
    opacity: 0.2;
    transition: all 0.2s;
    @include padding(null 15px);

    @media #{$pc}{
      &--only3 {
        opacity: 1;
        pointer-events: auto;
        &:hover {
          opacity: 0.7;
        }
      }
      &:not(.footer-campaign__slide--only3) {
        pointer-events: none;
        &.swiper-slide-prev,
        &.swiper-slide-active,
        &.swiper-slide-next {
          opacity: 1;
          pointer-events: auto;
        }
        &:hover {
          opacity: 0.7;
        }
      }
    }

    @media #{$sp}{
      max-width: 230px;
      @include padding(null 12px);
      &.swiper-slide-active {
        opacity: 1;
      }
    }
  }
}

.footer-nav {
  border-top: 1px solid #d7d7d7;
  @include padding(55px null);
  @media #{$sp}{
    @include padding(0 null);
  }
  
  &__wrap {
    @media #{$pc}{
      @include container;
    }
  }
}

.footer-copy {
  @include padding(35px null);
  background: #e2e2e2;

  @media #{$sp}{
    @include padding(25px null);
  }

  &__wrap {
    @include container;
    text-align: center;
  }

  &__list {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }

  &__item {
    &:not(:last-child) {
      margin-right: 30px;
    }
  }

  &__link {
    display: block;
    font-size: 13px;
    position: relative;
    padding-left: 15px;
    @include padding(5px null 5px 15px);
    transition: all 0.2s;

    @media #{$sp}{
      font-size: 12px;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      @include size(8px 3px);
      background: url(../img/common/arrow_right_black.svg) 50% / contain no-repeat;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    span {
      display: inline-block;
      border-bottom: 1px solid transparent;
      transition: all 0.2s;
    }
    
    &:hover {
      span {
        border-bottom: 1px solid;
      }
    }
  }

  &__txt {
    @include futura;
    font-size: 11px;
  }


}
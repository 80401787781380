/*
  ----------------
  ユーティリティー
  ----------------
*/

.container {
  @include container;
}

.clearfix {
  @include clearfix;
}

/*
  ----------------
  レスポンシブ用のユティリティー
  ----------------
*/

.sp-only {
  @media #{$pc} {
    display: none !important;
  }
}


.pc-only {
  @media #{$sp} {
    display: none !important;
  }
}

/*
  ----------------
  General utilies u-
  ----------------
*/

.u-fwb {
  font-weight: bold;
}

.u-ml-1em {
  margin-left: 1em;
}

/*
  ----------------
  Tag styles
  ----------------
*/

.tag-article {
  background: url(../img/common/icon_tag.svg) no-repeat left top;

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 30px;
  }

  &__item {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  &__link {
    font-size: 14px;
    color: #e10000;
    border-bottom: 1px solid transparent;
    transition: all .2s;

    &:hover {
      border-bottom: 1px solid;
    }
  }
}
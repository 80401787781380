/*
  ----------------
  About > Message
  ----------------
*/

.message {
  background: #ffffff;
}

.message-president {
  @include padding(75px null 70px);
  @include clearfix;

  @media #{$sp}{
    @include padding(35px null);
  }

  &__content {
  }

  &__img {
    float: left;
    width: 465px;
    margin: 0 30px 0 0;

    @media #{$sp}{
      width: 100%;
      margin: 0 0 10px 0;
    }
  }

  &__ttl-wrap {
    @media #{$sp}{
      display: none;
    }
  }

  &__ttl {
    display: inline-block;
    margin-right: 15px;
  }

  &__txt {
    line-height: 2.142;
    @media #{$sp}{
      line-height: 2;
    }
    p:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  &__name {
    text-align: right;
    font-weight: bold;
    margin-top: 35px;
  }
}


/*
  ----------------
  Company related styles
  ----------------
*/

.company {
  @include padding(70px null 80px);

  @media #{$sp}{
    @include padding(35px null 0);
    .list-primary {
      margin-right: 5px;
      margin-left: 5px;
    }
  }

  &__wrap {
    @include container;
  }

  .list-primary__txt {
    @media #{$sp}{
      margin-bottom: 30px;
    }
  }
}

/*
  ----------------
  Sectors related styles
  ----------------
*/

.bg-set{
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
}

.sectors {
  @include padding(80px null);

  @media #{$sp} {
      @include padding(35px null 40px);
  }
  &__wrap {
      @include container;
  }

  &__txt {
      line-height: 2.285;
      margin-bottom: 40px;

      @media #{$sp} {
          font-size: 14px;
          line-height: 2;
      }
  }
  .heading-primary{
      &__ttl{
          margin-bottom: (20-8)*1px;
      }
      &__subttl{
          display: block;
          margin-bottom: (40-0)*1px;
      }
  }
}

.sectors-graph {
  margin-bottom: 55px;

  @media #{$sp} {
      overflow: auto;
      margin-right: -15px;
      padding-right: 15px;
      img {
          max-width: none;
      }
  }
}

.sectors-banners {

  @media #{$sp} {
      overflow: auto;
      margin-right: -15px;
      padding-right: 15px;
      img {
          max-width: none;
      }
  }
}
#sectors-figure_01{
  display: block;
  width: 100%;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}
#sectors-figure_02{
  display: block;
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/*
  ----------------
  News related styles
  ----------------
*/

.news {
  @include padding(80px null 80px);
  background: #f2f2f2;
  @media #{$sp}{
    @include padding(40px null 40px);
  }

  &__wrap {
    @include container;
  }
}


.news-list {
  
  @media #{$sp}{
  }

  &__item {
    border-bottom: 1px dashed rgba($text-color, 0.5);

    &--news {
      border-bottom: 0;
      /*
      &:not(:nth-child(-n+5)) {
        display: none;
      }
      */
    }
  }

  &__link {
    position: relative;
    display: flex;
    align-items: flex-start;
    @include padding(15px);
    transition: all 0.2s;
    border-radius: 3px;

    @media #{$sp}{
      flex-wrap: wrap;
      @include padding(15px 0);
    }

    &:hover {
      background: rgba($accent-color, .1);
    }

    &--news {
      flex-wrap: wrap;
      @include margin(null -15px);

      @media #{$sp}{
        @include margin(null 0);
      }
    }
  }

  &__corp {
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    @include padding(5px 20px);
    border-radius: 3px;
    margin-right: 10px;
    flex-shrink: 0;
    line-height: 1;
    margin-bottom: 7px;
    @media #{$sp}{
      margin-bottom: 7px;
    }

    &--ggh {
      color: #fff;
      background: $accent-color;
    }

    &--singlemother {
      color: #fff;
      background: #f1004c;
    }

    &--athlete {
      color: #fff;
      background: #21267a;
    }

    &--ap {
      color: #fff;
      background: #6f360a;
    }

    &--eiasj {
      color: #fff;
      background: #b0991f;
    }

    &--careermate {
      color: #fff;
      background: #fd7d23;
    }

    &--sail {
      color: #fff;
      background: #1ba1ed;
    }

    &--mybase {
      color: #fff;
      background: #1d2b6b;
    }

    &--kokorozashi {
      color: #fff;
      background: #17a51b;
    }

    &--embex {
      color: #fff;
      background: #0d7044;
    }

    &--sokie {
      color: #fff;
      background: #074396;
    }

    &--fourhands {
      color: #fff;
      background: #92533f;
    }

    &--gmarks {
      color: #fff;
      background: #147240;
    }

    &--sports {
      color: #fff;
      background: #ee3f1f;
    }

    &--rccm {
      color: #fff;
      background: #2a5d9b;
    }

    &--athletesupp {
      color: #fff;
      background: #d8030e;
    }

    &--chihou {
      color: #fff;
      background: #339ed2;
    }

    &--joshikyari {
      color: #fff;
      background: #f654fb;
    }

    &--asappu {
      color: #fff;
      background: #e60362;
    }

    &--largotaka {
      color: #fff;
      background: #0253a0;
    }

    &--largokids {
      color: #fff;
      background: #f0902a;
    }

    &--ballschule {
      color: #fff;
      background: #f5ad1e;
    }

    &--apbaseball {
      color: #fff;
      background: #024097;
    }

  }

  &__icon-wrap {
    display: flex;
    @media #{$sp}{
      width: 100%;
    }
  }

  &__icon {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    background: #444;
    text-align: center;
    line-height: 1;
    @include padding(5px);
    width: 70px;
    border-radius: 3px;
    flex-shrink: 0;
    align-self: flex-start;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__date {
    margin-right: 60px;
    flex-shrink: 0;
    font-weight: bold;
    &--news {
      width: 105px;
      margin-right: 0;
      @media #{$sp}{
        width: 100%;
        margin-bottom: 5px;
        margin-right: 60px;
      }
    }
  }

  &__txt {
    flex-grow: 1;
    @media #{$sp}{
      width: 100%;
      margin-top: 10px;
      font-size: 14px;
    }
    &--news {
      width: 100%;
      padding-left: 105px;
      font-size: 16px;
      @media #{$sp}{
        padding-left: 0;
      }
    }
  }

  &__btn {
    @include margin(30px auto null);

    @media #{$sp}{
      @include margin(20px auto null);
    }
  }
}

.news-years {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 25px;
  @media #{$sp}{
    margin-bottom: 10px;
  }

  &__item {
    width: 144px;
    margin-bottom: 1px;
    font-size: 18px;
    &:not(:last-child) {
      margin-right: 1px;
    }
    @media #{$sp}{
      width: 28.8vw;
    }

    &--current a {
      color: #ffffff !important;
      border: 1px solid #e10000 !important;
      background: #e10000 !important;
      pointer-events: none !important;
    }
  }

  &__link,
  a {
    display: block;
    padding: 10px 0;
    @include futura;
    font-size: 18px;
    line-height: 1;
    text-align: center;
    color: #000000;
    border: 1px solid #000000;
    background: #ffffff;
    @media #{$sp}{
      padding: 8px 0;
      font-size: 14px;
    }
    &:hover {
      color: #ffffff;
      border: 1px solid #e10000;
      background: #e10000;
    }
  }
}

.news-detail {
  @include padding(80px null 45px);
  background: #fff;
  @media #{$sp}{
    @include padding(40px null 30px);
  }

  &__wrap {
    @include container;
  }

  &__btn {
    @include margin(null auto);
  }

}

.news-article {
  &__header {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px dashed #999999;
    @media #{$sp}{
      justify-content: flex-start;
    }
  }

  &__ttl {
    width: 100%;
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 15px;

    @media #{$sp}{
      font-size: 21px;
      width: 100%;
      text-align: left;
    }
  }

  &__corp {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    background: #f1004c;
    text-align: center;
    @include padding(5px 20px);
    border-radius: 3px;
    margin-right: 10px;
    margin-bottom: 4px;
    flex-shrink: 0;
    line-height: 1;
    @media #{$sp}{
      margin-bottom: 7px;
      @include padding(5px 10px);
    }
  }

  &__icon-wrap {
    display: flex;
    @media #{$sp}{
      width: 100%;
    }
  }

  &__icon {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    background: $text-color;
    text-align: center;
    line-height: 1;
    @include padding(5px);
    width: 70px;
    border-radius: 3px;
    margin-bottom: 4px;
    flex-shrink: 0;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__date {
    flex-shrink: 0;
    font-weight: bold;
    line-height: 1;
    @include padding(3px 10px 3px 0);

    @media #{$sp}{
      @include padding(5px 10px 5px 0);
    }
  }

  &__content {
    margin-bottom: 35px;
    padding-bottom: 30px;
    border-bottom: 1px dashed #999999;

    @media #{$sp}{
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
  }

  &__img {
    margin-bottom: 30px;
    text-align: center;
    img {
      max-width: 80%;
    }

    @media #{$sp}{
      img {
        width: 100%;
        max-width: 350px;
      }
    }
  }

  &__txt {
    flex-grow: 1;
    font-size: 18px;
    line-height: 2;
    margin-bottom: 60px;

    @media #{$sp}{
      line-height: 1.813;
      font-size: 16px;
      margin-bottom: 30px;
    }

    p:not(:last-child) {
      margin-bottom: 1.2em;
    }

    img {
      display: block;
      @include margin(null auto);
      &:not(:last-child) {
        margin-bottom: 1.2em;
      }
    }
  }
}

.news-relation {
  @include padding(50px null);
  background: #e5e5e5;
  @media #{$sp}{
    @include padding(30px null);
  }

  &__wrap {
    @include container;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__heading {
    width: 100%;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    @media #{$sp}{
      margin-bottom: 15px;
    }
  }

  &__list {
    @include row;
  }

  &__item {
    @media #{$pc}{
      width: 33.33%;
      @include padding(null 5px);
    }
    @media #{$sp}{
      width: 100%;
      margin-bottom: 5px;
    }    
  }

  &__link {
    display: flex;
    flex-wrap: wrap;
    @include padding(10px);
    border-radius: 3px;
    transition: all 0.2s;

    &:hover {
      background: rgba($accent-color, .1);
    }
  }

  &__ttl {
    width: 100%;
    font-size: 14px;
    line-height: 1.813;
  }

  &__corp {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    background: #f1004c;
    text-align: center;
    @include padding(5px 20px);
    border-radius: 3px;
    margin-right: 10px;
    margin-bottom: 4px;
    flex-shrink: 0;
    line-height: 1;
    @media #{$sp}{
      margin-bottom: 7px;
      @include padding(5px 10px);
    }
  }

  &__icon-wrap {
    display: flex;
    @media #{$sp}{
      width: 100%;
    }
  }
  
  &__icon {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    background: $text-color;
    text-align: center;
    line-height: 1;
    @include padding(5px);
    width: 70px;
    border-radius: 3px;
    margin-bottom: 4px;
    flex-shrink: 0;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__date {
    flex-shrink: 0;
    margin-bottom: 6px;
    @include padding(6px null);
    color: #666;
    line-height: 1;

    @media #{$sp}{
      width: 100%;
    }
  }

}

.news-nav {
  @include padding(40px null 80px);

  @media #{$sp}{
    @include padding(30px null 40px);
  }

  &__btn {
    margin: auto;
    background-color: #e10000;
    border-color: #e10000;
  }
}

#jp-relatedposts {
  display: none !important;
}
/*
  ----------------
  パンくずのスタイル設定
  ----------------
*/

.bread {
  padding-right: 12px;
  background: #f2f2f2;
  @media #{$sp}{
    padding-right: 15px;
  }
  &__list {
    @include container;
    @include padding(12px null);
    display: flex;
    align-items: center;
    overflow: auto;
  }

  &__item {
    position: relative;
    display: inline;
    white-space: nowrap;
    &:not(:last-child)::after {
      content: "＞";
      display: inline-block;
      font-size: 12px;
      @include margin(null 0.6em null 0.5em);
    }
  }

  &__link {
    font-size: 12px;
    color: $text-color;
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom: 1px solid $text-color;
    }
    &--current {
      font-weight: bold;
      pointer-events: none;
    }
  }
}
/*
  ----------------
  Nav styles
  ----------------
*/

.nav-primary {
  @media #{$pc}{
    display: flex;
    height: 100%;
  }
  @media #{$sp}{
    display: none;
    background: #fff;
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    z-index: 1;
  }
  &__list {
    display: flex;
    @media #{$pc}{
      height: 100%;
    }

    @media #{$sp}{
      flex-direction: column;
    }
  }

  &__item {
    @media #{$pc}{
      display: flex;
    }
  }

  &__subitem {
    font-size: 13px;
    @media #{$pc}{
      display: flex;
      height: 100%;
    }
    @media #{$sp}{
      @include padding(null 15px);
      &:not(:first-child) {
        border-top: 1px dashed rgba($text-color, .1);
        padding-left: 30px;
        display: none;
      }
    }
    
    &:first-child {
      font-size: 15px;
      color: $accent-color;
      @media #{$sp}{
        font-size: 12px;
        color: $text-color;
        border-top: 1px solid rgba($text-color, .3);
      }
    }

    @media #{$pc}{
      &:nth-child(2) {
        border-top: 1px dashed rgba($text-color, .3);
        padding-top: 10px;
      }
      &:not(:first-child) {
        display: none;
      }
    }


    &--more {
      @media #{$sp}{
        position: relative;
        padding-right: 40px;
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          @include size(39px 39px);
          background: #e5e5e5;
          border-left: 1px solid rgba($text-color, .3);
          border-bottom: 1px solid rgba($text-color, .3);
        }

        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          right: 15px;
          @include size(9px 9px);
          background: url(../img/common/icon_plus.svg) 50% / auto no-repeat;
          transform: translateY(-50%);
          transition: all 0.2s;
        }

        &.is-open {
          &::after {
            transform: translateY(-50%) rotate(90deg);
          }
        }
      }
    }
  }

  &__link {
    font-weight: bold;
    position: relative;
    @include padding(10px 20px);
    @media #{$pc}{
      display: flex;
      align-items: center;
      height: 100%;
      color: $text-color;
    }

    @media #{$sp}{
      display: block;
      transition: all 0.2s;
      @include padding(10px null 10px 25px);
      &::before {
        content: "";
        display: block;
        position: absolute;
        @include size(11px 5px);
        background: url(../img/common/arrow_right_red.svg) 50% / contain no-repeat;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
    

    span {
      display: inline-block;
      border-bottom: 1px solid transparent;
      @media #{$sp}{
        transition: all 0.2s;
      }
    }

    &:hover {
      @media #{$pc}{
        background: #e10000;
        color: #ffffff;
      }
    }

    &--current {
      @media #{$pc}{
        background: #e10000;
        color: #ffffff;
      }
    }

    &[target="_blank"] {
      span {
        position: relative;

        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 45%;
          right: -1.2em;
          background: url(../img/common/icon_blank.svg) 50% / contain no-repeat;
          @include size(11px 12px);
          transform: translateY(-50%);
        }
      }
    }
  }

}

.nav-ham {
  font-size: 0;
  @include padding(10px 15px);
  @include margin(null -15px);
  @media #{$pc}{
    display: none;
  }
}

.nav-article {
  &__list {
    display: flex;
    justify-content: space-between;
    @media #{$sp}{
      flex-wrap: wrap;
    }
  }
  &__item {
    width: calc(50% - 20px);
    @media #{$sp}{
      width: 100%;
      margin-bottom: 10px;
    }
    &--next {
      @media #{$pc}{
        text-align: right;
      }
    }
  }
  &__link {
    transition: all .2s;
    display: inline-block;
    color: #e10000;
    &--prev {
      padding-left: 28px;
      background: url(../img/common/arrow_left_red_large.svg) no-repeat left top 8px;
      background-size: 17px 6px;
      &:hover {
        transform: translateX(-15px);
      }
      @media #{$sp}{
        padding-left: 25px;
      }
    }
    &--next {
      padding-right: 28px;
      background: url(../img/common/arrow_right_red_large.svg) no-repeat right top 8px;
      background-size: 17px 6px;
      &:hover {
        transform: translateX(15px);
      }
      @media #{$sp}{
        padding-left: 25px;
        background-position: left center;
      }
    }
  }
}

.nav-footer {

  &__list {
    @media #{$pc}{
      @include row;
    }
    @media #{$sp}{
      border-bottom: 1px solid rgba($text-color, .3);
    }
  }

  &__item {
    @include col-3;
  }

  &__sublist {
    &:not(:first-child) {
      margin-top: 25px;
      @media #{$sp}{
        margin-top: 0;
      }
    }
  }

  &__subitem {
    font-size: 13px;
    @include padding(null 15px null null);
    &:first-child {
      font-size: 15px;
      color: $accent-color;
      @media #{$sp}{
        font-size: 12px;
        color: $text-color;
        border-top: 1px solid rgba($text-color, .3);
      }
    }

    @media #{$pc}{
      &:nth-child(2) {
        border-top: 1px dashed rgba($text-color, .3);
        padding-top: 10px;
      }
    }

    @media #{$sp}{
      @include padding(null 15px);
      &:not(:first-child) {
        border-top: 1px dashed rgba($text-color, .1);
        padding-left: 30px;
        display: none;
      }
    }

    &--more {
      @media #{$sp}{
        position: relative;
        padding-right: 40px;
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          @include size(39px 39px);
          background: #e5e5e5;
          border-left: 1px solid rgba($text-color, .3);
          border-bottom: 1px solid rgba($text-color, .3);
        }

        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          right: 15px;
          @include size(9px 9px);
          background: url(../img/common/icon_plus.svg) 50% / auto no-repeat;
          transform: translateY(-50%);
          transition: all 0.2s;
        }

        &.is-open {
          &::after {
            transform: translateY(-50%) rotate(90deg);
          }
        }
      }
    }
  }

  &__link {
    display: block;
    font-weight: bold;
    position: relative;
    @include padding(5px null 5px 15px);
    transition: all 0.2s;
    @media #{$sp}{
      @include padding(10px null 10px 15px);
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      @include size(8px 3px);
      background: url(../img/common/arrow_right_red.svg) 50% / contain no-repeat;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      @media #{$sp}{
        @include size(11px 5px);
      }
    }

    span {
      display: inline-block;
      border-bottom: 1px solid transparent;
      transition: all 0.2s;
    }

    &:hover {
      transform: translateX(10px);
      span {
        border-bottom: 1px solid;
      }
    }

    &[target="_blank"] {
      span {
        position: relative;

        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 45%;
          right: -1.2em;
          background: url(../img/common/icon_blank.svg) 50% / contain no-repeat;
          @include size(11px 12px);
          transform: translateY(-50%);
        }
      }
    }
  }
}

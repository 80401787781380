/*
  ----------------
  Cards
  ----------------
*/

.card-primary {
  position: relative;
  overflow: hidden;
  display: block;
  &__header {
    background-color: $accent-color;
    display: flex;
    align-items: center;
    min-height: 90px;
    @include padding(null 30px);
    position: relative;

    @media #{$sp}{
      @include padding(null 15px);
      min-height: 50px;
    }
    &::after {
      content: "";
      display: block;
      @include size(18px 7px);
      background: url(../img/common/arrow_right.svg) 50% / contain no-repeat;
      position: absolute;
      bottom: 10px;
      right: 10px;

      @media #{$sp}{
        width: 14px;
      }
    }
  }

  &__ttl {
    font-size: 16px;
    font-weight: bold;
    color: #fff;

    @media #{$sp}{
      font-size: 14px;
    }
    
  }

  &__subttl {
    font-weight: bold;
    color: #fff;
    margin-left: auto;

    @media #{$sp}{
      font-size: 10px;
    }
  }

  &__img {
    font-size: 0;
    position: relative;
    overflow: hidden;
    img {
      transition: all 0.4s;
    }
  }

  &__content {
    @media #{$pc}{
      position: absolute;
      top: 100%;
      left: 0;
      height: 100%;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      @include padding(20px);
      display: flex;
      align-items: center;
      background: rgba(#000, .7);
      transition: 0.2s ease-out;
    }

    @media #{$sp}{
      font-size: 12px;
      margin-top: 1em;
    }
  }
  
  &:hover {
    @media #{$pc}{
      // .card-primary__content {
      //   top: 0;
      // }
      .card-primary__img {
        img {
          transform: scale(1.1);
        }
      }
    }
  }

}
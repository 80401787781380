// Breakpoint設定

$sp: "all and (max-width : 768px)";

$pc: "all and (min-width: 769px)";

// Font-family

$font-family: 'Noto Sans Japanese','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','游ゴシック','Yu Gothic','游ゴシック体','YuGothic','ＭＳ Ｐゴシック','MS PGothic',sans-serif;

// カラー


$accent-color: #e10000;

// テキストカラー

$text-color: #000;


// コンテナー設定

$container: 960px;

// グリッド設定

$gutter: 30px;
$gutter-half: $gutter / 2;
$small-gutter: 10px;


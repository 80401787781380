/*
  ----------------
  Contact related styles
  ----------------
*/

.contact {
  @include padding(30px null 80px);
  background: #f2f2f2;

  @media #{$sp}{
    @include padding(30px null 40px);
  }

  &__wrap {
    @include container;
  }
}

.contact-form {
  
  &__wrap {
    @include padding(10px null);
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    @media #{$sp}{
      @include padding(0px null);
      flex-direction: column;
    }
  }

  &__label {
    flex-shrink: 0;
    @include padding(13px 20px);
    margin-bottom: 20px;
    width: 220px;
    background: #787878;
    margin-right: 30px;
    font-weight: bold;
    line-height: 1;
    color: #fff;

    @media #{$sp}{
      @include padding(12px);
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
      font-size: 14px;
    }
  }

  &__input {
    flex-grow: 1;
    margin-bottom: 20px;
    width: calc(100% - 260px);

    @media #{$sp}{
      width: 100%;
      margin-bottom: 20px;
      font-size: 14px;
    }

    input,
    textarea,
    select {
      width: 100%;
      height: 40px;
      @include padding(10px 15px);
      font-size: 14px;
      border-radius: 3px;
      border: 1px solid #dfdfdf;
      background-color: #f9f9f9;
      outline-color: transparent;
      outline-width: 3px;
      @media #{$sp}{
        @include padding(6px 12px);
        height: 38px;
      }

      &:focus {
        outline-color: rgba($accent-color, .1);
      }
    }
    textarea {
      height: auto;
      resize: vertical;
    }
    select {
      appearance: none;
      background: #f9f9f9 url(../img/common/chevron_down.svg) calc(100% - 15px) 50% / auto no-repeat;
    }
    @media screen and (-webkit-min-device-pixel-ratio:0) and (max-width : 768px) { 
      select,
      textarea,
      input {
        font-size: 16px;
      }
      input, input:before, input:after {
        -webkit-user-select: initial;
        -khtml-user-select: initial;
        -moz-user-select: initial;
        -ms-user-select: initial;
        user-select: initial;
       } 
    }
  }

  &__privacy {
    @include padding(30px 50px);
    background: #fff;
    border: 1px solid #ccc;
    line-height: 1.7;
    height: 230px;
    overflow: auto;
    margin-bottom: 40px;

    @media #{$sp}{
      @include padding(15px 12px);
      height: 200px;
      margin-bottom: 30px;
      font-size: 14px;
    }

    p:not(:last-child) {
      margin-bottom: 1.5em;
    }
  }

  &__accept {
    text-align: center;
    line-height: 2;
    margin-bottom: 30px;

    @media #{$sp}{
      font-size: 14px;
    }

    input {
      appearance: none;
      @include size(22px);
      border: 1px solid #ccc;
      vertical-align: middle;
      cursor: pointer;
      &:checked {
        background: url(../img/common/icon_check.svg) 50% / auto no-repeat;
      }
      &:focus {
        outline-color: rgba($accent-color, .1); 
      }
    }
    label {
      vertical-align: middle;
      cursor: pointer;
    }
  }

  &__btn {
    @include margin(null auto);

    &:hover {
      padding-left: 45px;

      @media #{$sp}{
        padding-left: 35px;
      }
    }

    &[disabled] {
      background-color: #ccc;
      border: 1px solid #ccc;
      pointer-events: none;
    }
  }

}
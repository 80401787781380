/*
  ----------------
  Company related styles
  ----------------
*/

.about {
  @include padding(60px null 80px);

  &__wrap {
    @include container;
  }

  &__list {
    @media #{$pc}{
      @include row;
    }

    @media #{$sp}{
      @include padding(null 15px);
    }
  }

  &__item {
    @media #{$pc}{
      @include col-6;
      &:not(:nth-child(-n+2)) {
        margin-top: 30px;
      }
    }

    @media #{$sp}{
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
}